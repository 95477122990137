<template>
  <div class="kt-menu__nav">
    <KTSubheader v-bind:title="titleBreadcrumb"></KTSubheader>
    <span> </span>
  </div>
</template>

<script>
import KTMenuItem from "@/components/header/MenuItem.vue";
import menuConfig from "@/common/config/menu.config.json";
import KTSubheader from "@/components/header/Subheader.vue";
import { mapGetters } from "vuex";

export default {
  name: "KTHeaderMenu",
  components: {
    KTMenuItem,
    KTSubheader
  },

  computed: {
    menuItems: () => {
      return menuConfig.header.items;
    },

    ...mapGetters(["isAuthenticated", "titleBreadcrumb"])
  }
};
</script>

<template>
  <div
    id="kt_header_mobile"
    class="kt-header-mobile"
    v-bind:class="headerClasses"
  >
    <div class="kt-header-mobile__toolbar">
      <button
        class="kt-header-mobile__toggler kt-header-mobile__toggler--left"
        id="kt_aside_mobile_toggler"
      >
        <span></span>
      </button>
      <!-- <span
        class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
        @click="onLogout"
        >Đăng xuất</span> -->
    </div>
    <div class="mt-3">
      <span class="email-user mr-5">{{ emailUser }}</span>
      <span class="mt-3" @click="logout">Đăng xuất</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTToggle from "@/assets/js/toggle.js";
import Swal from "sweetalert2";
import { LOGOUT } from "@/store/auth.module";

export default {
  name: "KTHeaderMobile",
  components: {},
  data() {
    return {
      emailUser: ""
    };
  },
  mounted() {
    this.getEmailUser();
    new KTToggle(this.$refs["kt_header_mobile_topbar_toggler"], {
      target: "body",
      targetState: "kt-header__topbar--mobile-on",
      togglerState: "kt-header-mobile__toolbar-topbar-toggler--active"
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "originNavState"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.dark");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  },

  methods: {
    logout() {
      Swal.fire({
        icon: "warning",
        text: "Bạn có chắc muốn đăng xuất?",
        confirmButtonText: "Đăng xuất",
        cancelButtonText: "Không",
        showCancelButton: true,
        reverseButtons: true
      }).then(res => {
        if (res.value) {
          this.$store.dispatch(LOGOUT).then(() => {
            this.$router.push({ name: "login", replace: true });
          });
        }
      });
    },

    getEmailUser() {
      const dataUser = localStorage.getItem("user");
      const dataUserConvert = JSON.parse(dataUser);
      this.emailUser = dataUserConvert.email;
    }
  }
};
</script>

<template>
  <div class="kt-grid__item kt-subheader__main" id="kt_subheader">
    <div class="title-subheader">
      {{ title }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTSubheader",
  props: {
    title: String
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    }
  }
};
</script>

<style lang="scss">
@import "./Header.scss";
</style>

<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">
    <!--begin: User Bar -->
    <span class="email-user mr-3">{{ emailUser }}</span>
    <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div
        class="kt-header__topbar-wrapper"
        id="kt_user_toggle"
        data-toggle="dropdown"
      >
        <div class="kt-header__topbar-user">
          <span
            class="kt-header__topbar-username kt-hidden-mobile"
            @click="onLogout"
            >Đăng xuất</span
          >
          <!-- <span class="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>
          <span class="kt-header__topbar-username kt-hidden-mobile">Quang</span>
          <img class="kt-hidden" alt="Pic" src="#" />
          <span
            class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
            @click="onLogout"
            >Đăng xuất</span
          > -->
        </div>
      </div>
      <!-- <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownUser></KTDropdownUser>
      </div> -->
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import KTDropdownUser from "@/components/topbar/DropdownUser.vue";
import Swal from "sweetalert2";
import { LOGOUT } from "@/store/auth.module";

export default {
  name: "KTTopbar",
  components: {
    KTDropdownUser
  },
  data() {
    return {
      emailUser: ""
    };
  },
  created() {
    const dataUser = localStorage.getItem("user");
    const dataUserConvert = JSON.parse(dataUser);
    this.emailUser = dataUserConvert.email;
  },
  methods: {
    onLogout() {
      Swal.fire({
        icon: "warning",
        text: "Bạn có chắc muốn đăng xuất?",
        confirmButtonText: "Đăng xuất",
        cancelButtonText: "Không",
        showCancelButton: true,
        reverseButtons: true
      }).then(res => {
        if (res.value) {
          this.$store.dispatch(LOGOUT).then(() => {
            this.$router.push({ name: "login", replace: true });
          });
        }
      });
    }
  }
};
</script>
